.distributor {
  width: 1200px;
  margin: 0% auto;
}

.tableContainer {
  /* width: 750px; */
  overflow-x: auto;
}

.table_distributor {
  width: 100%;
  margin-top: 10px;
  border: 2px solid gray;
}

.table_distributor > tbody > tr > td {
  font-size: 10px;
  font-weight: 500;
  padding: 0;
}

.table_distributor:not(.caption-container) td {
  padding: 3px;
  padding-left: 5px;
  border: 1px solid rgb(187, 187, 187);
}

.type {
  width: 10%;
}

.table_data-Heading {
  /* border: 2px solid gray; */
  border-bottom: none;
  margin-top: 10px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
}

.table_distributor-data {
  width: 100%;
  /* margin-top: 10px; */
  border: 2px solid gray;
}

.table_distributor-data>tbody>tr>td {
  /* font-size: 11px; */
  font-size: 8pt;
  font-weight: 500;
}

.table_distributor-data:not(.caption-container) td {
  padding: 0px;
  padding-left: 5px;
  border: 1px solid rgb(187, 187, 187);
}

.table_distributor-data.table-auto-adjust:not(.caption-container) td {
  white-space: nowrap;
}


.tdwidth{
  width: 22px;
}

.select_input {
  width: 230px;
}

.table_distributor .ant-picker {
  /* width: 72.5% !important; */
  width: 210px;
}

/*New Css for OmsOtsDetails*/
.dynamic_checkbox {
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
  border: none !important;
}

.dynamic_checkbox .all_checkbox {
  padding: 3px;
  padding-left: 5px;
  border: 1px solid rgb(187, 187, 187);
  margin-bottom: 5px;
  margin-right: 4px;
}

.all_checkbox .ant-form-item-control-input {
  min-height: 5px;
}

.all_checks {
  margin-bottom: 5px;
  margin-right: 4px;
}

.all_checks .ant-form-item {
  margin-bottom: 0px !important;
}

.all_checks .ant-form-item-control-input {
  min-height: 5px !important;
}

.no-Data {
  margin-top: 20px;
  height: 200px;
  background-color: rgb(238, 238, 238);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #3C4B58;
}
.intransit .ant-table th,.intransit .ant-table td{
  font-size: 9.3px !important;
}
.intransit .ant-table th{
  vertical-align: bottom;
}

.oms_stock_summary_table tbody tr:first-child td:hover{
cursor:pointer !important
}

.oms_stock_summary_table th:after,
th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}
.oms_stock_summary_table th:before {
  top: -2.5px;
  border-top: 2px solid gray;
}
.oms_stock_summary_table th:after {
  bottom: -3px;
  border-bottom: 2px solid gray;
}

.immedidate_ots_3  th, .immedidate_ots_3 td{
font-size: 9px !important;
}

/* .intransit .ant-table-thead>tr>th:nth-child(1){ 
  font-weight: 900 !important;
  } */
@media screen and (min-width:1880px) {
  .no-Data{
    margin: 1% 14%;
  }
}

.no-Data > h1 {
  font-size: 32px;
  color: #3C4B58;
}

.font-weig{
  font-weight: bolder !important;
}
.overflow{
  overflow: auto;
}

@media screen and (max-width:1300px) {
  .overflow{
    overflow: auto;
    height: 59vh;
  }
}

.fitWidth{
  width: fit-content;
}
@media print{
  .overflow{
    width: 1300px !important;
    overflow: visible;
  }
  .table_distributor-data{
    overflow: visible !important;
    width: 1224px !important;
    margin-right: 2px 0 !important;
  }
  .distributor{
    /* margin: 0 !important; */
  }
  .distributr{
    width: 1200px !important;
  }
.business_header_oms{
  width: auto !important;
  margin: 0 !important;
}
/* .business_class_oms{
  width: 1100px !important;
} */
.row{
  margin: 0 !important;
}
}

@media screen and (max-width: 1280px) {
  .distributor {
    width: 940px;
  }
}


@media screen and (max-width: 1050px) {
  .distributor {
    width: 100%;
  }

  .tableContainer {
    width: 100%;
  }
}