.crudContainer{
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px 3px 10px;
}

.crudContainer .ant-divider-horizontal{
    margin: 0 !important;
}

* :disabled {
    /* background-color: white !important;
 */
    color: black; 
}
.crudContainer .iconBtn:hover{
    color:#8b8b8b
}

.crudBTN{
    margin-left: 5px;
}
.fspx-12 {font-size: 12px;}
.buttons-div {
    display: flex;
    align-items: center;
}
.mr-5{
    margin-right: 5px;
}