.orderRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.orderCols {
  flex-shrink: 1;
  width: 23%;
  min-width: 220px;
  margin: 0 10px;
}

.orderCols .ant-form-item-label>label {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.summaryCard {
  /* background: rgb(236 236 236); */
  border-radius: 10px;
  height: 93%;
  width: 45vw;
  padding-bottom: 10px;
}

.summaryTitle {
  display: flex;
  padding-top: 10px;
  font-size: 14px;
  justify-content: center;
}

.summaryRows {
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 25px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.b-radius-5 {
  border-radius: 5px;
}

.b-radius-10 {
  border-radius: 10px;
}

.pbpx-5 {
  padding-bottom: 5px;
}

.pbpx-10 {
  padding-bottom: 10px;
}

.pbpx-15 {
  padding-bottom: 15px;
}

.pbpx-20 {
  padding-bottom: 20px;
}

.pbpx-25 {
  padding-bottom: 25px;
}

.pbpx-30 {
  padding-bottom: 30px;
}

.pbpx-35 {
  padding-bottom: 35px;
}

.pbpx-40 {
  padding-bottom: 40px;
}

.pbpx-45 {
  padding-bottom: 45px;
}

.pbpx-50 {
  padding-bottom: 50px;
}

.w-100 {
  width: 100%;
}

.bg-white {
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .orderCols {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .orderRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
}

/* //// */
.firstSection {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 0 0;
  flex-wrap: wrap;
}

.firstSectionRow {
  width: 400px;
  min-width: 400px;
  margin: 0 25px;
  flex-shrink: 1;
}

.secondSectionRow .ant-form-item {
  margin-bottom: 5px !important;
}

.secondSection {
  display: flex;
  /* justify-content: center; */
  width: 100%;
  gap: 1%;
  /* padding: 0 10px; */
  flex-wrap: wrap;
}


.ant-card-head-title {
  text-align: center !important;
}

.cardTitle {
  /* width: 49.5%; */
  /* min-width: 400px; */
  margin: 5px 0 !important;
  border-radius: 10px !important;
  /* border-color: gray !important; */
}

.ant-col-lg-10 {
  height: 3em;
}


.ant-transfer {
  flex-direction: column !important;
}

.sale_order_line_item_fix>.ant-table-container {
  height: 500px !important;
}

.sale_order_line_item_fix .ant-table-container .ant-table-body {
  overflow: scroll !important;
  height: 415px !important;
}

@media screen and (max-width: 1300px) and (min-width: 991px) {
  .name-field-form .ant-col-lg-2 {
    flex: 0 0 12.333333%;
    max-width: 12.333333%;
  }
}

@media only screen and (max-width: 930px) {
  .firstSection {
    justify-content: center;
  }

  .secondSection {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .cardTitle {
    width: 50%;
  }
}

@media only screen and (max-width: 1300px) {
  .firstSection {
    justify-content: center;
  }

  .secondSection {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .cardTitle {
    width: 100%;
  }
}

.firstSectionDate {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 10px 0;
  flex-wrap: wrap;
}

.ant-collapse-header {
  background: #e0e6ed !important;
  color: #000000d9 !important;
  font-weight: 600 !important;
}


/* .customer_email_modal > p {
  padding: 0px;
  margin: ;
} */

.customer_email_table {
  /* border-collapse: collapse; */
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
}

.customer_email_table>th {
  border: 1px solid black;
  padding: 8px;
}

.customer_email_table>tr {
  border: 1px solid black;
  padding: 8px;
}

.customer_email_table>tr>td {
  font-size: 12px;
  padding: 5px 0px 5px 0px;
}
.customer_email_table>tr>th {
  font-size: 12px;
  font-weight: 700;
}

.customer_email_modal>.customer_email_heading {
  font-weight: 700 !important;
  /* font-size: 14px; */
}

@media (min-width: 1200px) {

  .ant-col-lg-52 {
    display: block;
    flex: 0 0 20.13333333%;
    max-width: 20.3333333%;
  }

  .ant-col-lg-162 {
    display: block;
    flex: 0 0 16.2%;
    max-width: 16.2%;
  }
}


@media (min-width: 1282px) {

  .ant-col-lg-52 {
    display: block;
    flex: 0 0 20.3333333% !important;
    max-width: 20.13333333% !important;
  }

  .ant-col-lg-162 {
    display: block;
    flex: 0 0 16.2% !important;
    max-width: 16.2% !important;
  }
}

@media (min-width: 1600px) {
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667% !important;
    max-width: 41.66666667% !important;
  }
}