.orderRow {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
}
.orderCols {
  flex-shrink: 1;
  width: 23%;
  min-width: 220px;
  margin: 0 10px;
}
.orderCols .ant-form-item-label > label {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}
.summaryCard {
  /* background: rgb(236 236 236); */
  border-radius: 10px;
  height: 93%;
  width: 45vw;
  padding-bottom: 10px;
}
.summaryTitle {
  display: flex;
  padding-top: 10px;
  font-size: 14px;
  justify-content: center;
}
.summaryRows {
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 25px;
}
/* .ant-input.ant-input-borderless, .ant-input.ant-input-borderless-disabled, .ant-input-borderless-focused, .ant-input.ant-input-borderless:focus,
.ant-input.ant-input-borderless:hover, .ant-input.ant-input-borderless[disabled] {
  background-color: transparent!important;
  border: none!important;
  box-shadow: none!important;
} */
.fw-400 { font-weight: 400; }
.fw-500 { font-weight: 500; }
.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700; }
.b-radius-5 { border-radius: 5px; }
.b-radius-10 { border-radius: 10px; }
.pbpx-5 { padding-bottom: 5px;}
.pbpx-10 { padding-bottom: 10px;}
.pbpx-15 { padding-bottom: 15px;}
.pbpx-20 { padding-bottom: 20px;}
.pbpx-25 { padding-bottom: 25px;}
.pbpx-30 { padding-bottom: 30px;}
.pbpx-35 { padding-bottom: 35px;}
.pbpx-40 { padding-bottom: 40px;}
.pbpx-45 { padding-bottom: 45px;}
.pbpx-50 { padding-bottom: 50px;}

.w-100 {width:100%; }

.bg-white{ background-color:white; }

@media only screen and (max-width: 600px) {
  .orderCols {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .orderRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
}

/* //// */
.firstSection {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 0 0;
  flex-wrap: wrap;
}

.firstSectionRow {
  width: 400px;
  min-width: 400px;
  margin: 0 25px;
  flex-shrink: 1;
}

/* .customInputs {
  border-radius: 10px !important;
}
 */
/* .customInputs:hover {
  border-color: gray !important;
  color: gray !important;
}
.customInputs:focus {
  box-shadow: none !important;
  border-color: gray !important;
} */

/* //// */
.secondSectionRow .ant-form-item {
  margin-bottom: 5px!important;
}
.secondSection {
  display: flex;
  /* justify-content: center; */
  width: 100%;
  gap: 1%;
  /* padding: 0 10px; */
  flex-wrap: wrap;
}


.ant-card-head-title {
  text-align: center !important;
}
.cardTitle {
  /* width: 49.5%; */
  /* min-width: 400px; */
  margin: 5px 0 !important;
  border-radius: 10px !important;
  /* border-color: gray !important; */
}

.ant-col-lg-10 {
  height: 3em;
}


.ant-transfer {
  flex-direction: column !important;
}

@media screen and (max-width: 1300px) and (min-width: 991px) {
  .name-field-form .ant-col-lg-2 {
    flex: 0 0 12.333333%;
    max-width: 12.333333%;
  }
}

@media only screen and (max-width: 930px) {
  .firstSection {
    justify-content: center;
  }
  .secondSection {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .cardTitle {
    width: 50%;
  }
}
@media only screen and (max-width: 1300px) {
  .firstSection {
    justify-content: center;
  }
  .secondSection {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .cardTitle {
    width: 100%;
  }
}

.firstSectionDate {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 10px 0;
  flex-wrap: wrap;
}
.ant-collapse-header {
  background: #e0e6ed  !important;
  color: #000000d9 !important;
  font-weight: 600 !important;
}

/* .saleOrderForm .primaryInfo .secondSection .ant-col {
  height: 35px !important;
} */
