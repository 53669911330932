.ant-tabs-tab{
    width: 100% !important;
    padding: 5px 0 !important;
    justify-content: center;
    color: gray;
    font-weight: 400;
    border: 1px solid #e5e5e5  !important;
    /* border-radius: 5px !important; */
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    /* border-radius: 10px; */
}