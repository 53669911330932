.dashboard-container {
  /* max-width: 1400px;
  margin: 0 auto;
  display: table; */
  padding: 10px 10px;
}

.dashboard-container .dashboard-hd-main {
  font-size: 25px;
  font-weight: 700 !important;
  /* padding: 0 0 15px 0; */
}

.dashboard-card-main {
  -webkit-box-shadow: rgba(140, 152, 164, .075) 0 6px 12px 0;
  -moz-box-shadow: rgba(140, 152, 164, .075) 0 6px 12px 0;
  box-shadow: #8c98a413 0 6px 12px;
  border: 1px solid rgba(231, 234, 243, .7);

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Lato, sans-serif;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  /* margin-bottom: 10px; */
}

.filter-card {
  margin-bottom: 10px;
}

.dashboard-card-content {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-card-main .dashboard-card-hd {
  font-weight: 700 !important;
  font-size: 18px;
  padding-bottom: 10px;
}

.dashboard-card-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.dashboard-card-content .dashboard-card-text .dashboard-card-text-left {
  font-weight: 700 !important;
  font-size: 18px;
}

.dashboard-card-content .dashboard-card-text .dashboard-card-text-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.dashboard-card-content .dashboard-card-text .dashboard-card-text-right .stats-card {
  font-size: 16px;
}

.dashboard-card-content .dashboard-card-text .dashboard-card-text-right .stats-value {
  font-size: 18px;
}

.dashboard-card-content .dashboard-card-text .dashboard-card-text-right .stats-icon {
  font-size: 16px;
  position: relative;
  top: -2px;
}

.dashboard_filters_container {
  margin-bottom: 20px;
}

.dashboard_filters_container>div {
  margin-bottom: 10px;
}

.dashboard_tag {
  padding: 7px 25px 7px 25px !important;
  cursor: pointer;
  border-radius: 5px !important; 
  margin-bottom: 5px !important;
  /* width: 120px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  /* padding: 2px 10px 2px 10px !important;
  cursor: pointer;
  border-radius: 5px !important; */
}

.dashboard-card-content .filter-hd {
  font-size: 14px;
  margin-bottom: 2px;
  margin-top: 5px;
  font-weight: bold !important;
}

.dashboard_tag_active {
  /* border-color: 3px solid #3c4b58 !important; */
  background-color: #3c4b58 !important;
  color: #fff !important;
}


.dashboard-chart-container {
  display: flex;
  align-items: center;
  gap: 40;
}

.dashboard-chart-label {
  font-size: 12px;
}

.dashboard-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.08);
}

.no_charts_data {
  font-size: 18px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.color_tag_active_1 {
  background-color: #007ea7 !important;
  color: #fff !important;
}

.color_tag_active_2 {
  background-color: #598392 !important;
  color: #fff !important;
}




.charts_container {
  opacity: 0.7;
  padding: 0%;
}