.sales_report_view  .sales-report {
    width: 100%;
    margin: 0% auto;
    background-color: white;
    /* margin-top: 5px; */
  }
  
  .sales_report_view  .sales-report-header {
    display: flex;
    width: 109%;
    margin-left: 116px;
    
    /* flex-wrap: wrap; */
    background-color: white;
  }
  
 .header-left-side-return {
    width: 77%;
  }
  
  .sales_report_view  .sales-report-Logo-Container {
    display: flex;
    gap: 10px;
  }
  
  .sales_report_view  .sales-report-Logo_items {
    line-height: 1.4;
    font-weight: 600;
  }
  
  .sales_report_view  .items-detail-container {
    display: flex;
    margin-top: 5px;
    width: 100%;
  }
  
  .sold-to-Container2 {
    width: 50%;
  }
  
  .sold-to {
    font-weight: 600;
    display: flex;
    background-color: rgb(255, 255, 255);
  }
  .sold-to > div {
    width: 55%;
    border-bottom: 1px solid black;
  }

  .sold-to-return {
    font-weight: 600;
    display: flex;
    background-color: rgb(255, 255, 255);
  }
  .sold-to-return > div {
    width: 20%;
    border-bottom: 1px solid black;
  }
  
  .sold-to-data {
    width: 100% !important;
    text-align: left;
  }
  
  .ship-to-Container {
    width: 50%;
    margin: 0px 10px;
  }
  
  .header-right-side-return {
    width: 20%;
    border: 1px solid black;
    border-style: dashed;
    padding: 5px;
    height: fit-content;
  }
  
  .sales-order-heading {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
  }
  
  .sales-order-items-container:not(.sales_order_content_return) {
    display: flex;
    margin-top: 5px;
    font-size: 10px;
  }
  .sales_order_content_return{
    width: 40%;
    font-size: 9px;
  }
  .sales_order_content_return>div{
    margin-bottom: 1.8px;
  }
  .sales-order-items-left-return {
    width: 60%;
    font-weight: 600;
  }
  
  .sales-report-Table-data {
    width: 85%;
    margin: 0% auto;
    /* margin-top: 50px; */
  }
  
  .sales-report-table {
    width: 100%;
    /* border: 1px solid black;
    border-style: dashed; */
    margin-top: 20px;
  }
  
  .sales-return-table-total{
    /* margin-left: 72.5%; */
    margin-top: 10px;
  width: 20%;
  border: 1px solid black;
  border-style: dashed;
  font-size: 10px;
  }
  .signature{
    margin-left: 78%;
    margin-top: 120px;
    text-align: center;
    width: 154px;
    border-top:1px solid black
  }
  .signature>div:before{
    content : "";
    position: absolute;
    left    : 112px;
    bottom  : 0px;
    height  : 1px;
    width   : 834px;
    border-bottom:1px dashed black;
  }
  .signature hr{
    height: 2px;
    width: 203px;
    margin-left: -35px;
    color: black;
  }
  .sales-return-table-total td{
    padding: 0;
    padding-left: 5px;
    border: 1px solid black;
    border-style: dashed;
  }
  
  .sales-report-table > tbody > tr > td {
    font-size: 10px;
    font-weight: 500;
  }
  
  .sales-report-table:not(.caption-container) td {
    padding: 0;
    padding-left: 5px;
    border: 1px solid black;
    border-style: dashed;
  }
  
  .sales-report-footer {
    background-color: rgb(61, 67, 146);
    margin-top: 50px;
    width: 100%;
    height: 200px;
  }
  
  .sales_table_col{
    width: 50px;
  }
  .overflow_txt{
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .font_weight{
    font-weight: 700 !important;
  }
  @media screen and (max-width: 1550px){
    .return-footer>div{
      width: 81% !important;
      margin-left: -31px;
    } 
    .sales-report-header{
      margin-left: 9% !important;
    }
    .sales-report-footer{
      width: 85% !important;
    }
    .order_report_footer{
      width: 100%;
    }
    }
  @media screen and (max-width: 1350px){
    .sales_report_view .return-footer>div{
      width: 80% !important;
      margin-left: -3px;
    } 
    .sales-report-header{
      margin-left: 10% !important;
    }
    .sold-to-Container2 {
      width: 70%;
  }
  }
  @media screen and (max-width: 1200px) {
    .return-footer>div{
      width: 81.5% !important;
      margin-left: -22px;
    } 
    .sales-report {
      width: 95%;
    }
    .header-left-side-return {
      width: 100%;
    }
  
    .header-right-side-return {
      width: 40%;
    }
    /* .sold-to-Container {
      width: 100%;
    } */

    .sales_report_view .sales-report-Logo-Container > div > img{
      width: 150px;
      margin: 0 10px;
      height: 26px;
    }
    .sales_report_view .sales-report-Logo-Container > div:first-child{
      display: flex;
      align-items: center;
    }
  }
  
  @media print {
    .header {page-break-after: avoid;}
    tr{page-break-inside: avoid; 
      page-break-after: auto;}
  }
  
  .page {
    page-break-after: always;
  }
  
  .return-footer {
    /* height: 185px; */
    height: 145px;
    justify-content: center;
    margin-bottom: 10px;
  
  }
  
  .return-footer>div {
    margin-left: -14px;
    position: fixed;
    top: 0;
    /* width: 100vw; */
    width: 1080px;
    /* width: 57%; */
    height: 135px;
    /* margin-bottom: 30px; */
    font-size: 9px;
    background-color: white;
  }
  
  .return-footer2 {
    height: 209px;
    /* background-color: aquamarine; */
    justify-content: center;
    margin-bottom: 10px;
    display: flex;
  }
  
  .return-footer2>div {
    margin-left: -14px;
    position: fixed;
    bottom: 0;
    /* width: 1080px; */
    width: 1348px;
    height: 240px;
    background-color: white;
  }

  body {
    font-size: 42px;
    font-family: sans-serif;
  }
  
  .spin {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }
  
  .return_sales_person_table{
        width: 20rem !important;
  }
  .total_cell{
    width: 30%;
  }
  @media print {

    .return-footer{
      margin-left: 0px !important;
      margin-bottom: 10px;
      padding-bottom: 10px;
      width: 700px !important;
    }
    .return-footer>div{
      margin-top: 0.5px;
      /* margin-left: -40px !important;
      width: 744px !important; */
      /* margin-left: -110px !important; */
      width: 988px !important;
    }
    .return-footer>div:before {
      content : "";
      position: absolute;
      left    : 112px;
      bottom  : 0px;
      height  : 1px;
      width   : 834px;
      /* border-bottom:1px dashed black; */
    }
    .return-footer2{
        margin-left: 0px !important;
        margin-bottom: 10px;
        padding-bottom: 10px;
        width: 700px !important;
        display: block;
      }
     .return-footer2>div{
        margin-left: -75px;
        /* margin-left: 10px; */
        position: fixed;
        bottom: 0;
        /* width: 539px; */
        width: 1260px;
        height: 210px;
        background-color: white;
      }
    .sales-return-table-total{
      /* margin-left: 62.5%; */
      /* margin-left: 70%; */
      margin-top: 10px;
      width: 30% !important;
      border: 1px solid black;
      border-style: dashed;
      font-size: 10px;
    }
    .sales_report_view .total_amount{
      width: 20% !important;
    }
    .sales_report_view  .empty_cell{
      width: 14% !important;
    }
    .sales_report_view  .sum_cell{
      /* width: 100% !important; */
    }
    .sales_report_view  .total_cell{
      /* width: 49% !important; */
      width: 15% !important;
    }
    .sales_report_view  .sales-report-Table-data{
      width: 100%;
    }
    .sales_report_view .sales-report-header{
      width:115% !important;
    }
    .sales_report_view .sales-report-Logo-Container > div > img{
      width: 150px;
      margin: 0 10px;
      height: 26px;
    }
    .sales_report_view .sales-report-Logo-Container > div:first-child{
      display: flex;
      align-items: center;
    }

    .sales_return_view .sales-report-header{
        margin-left: 1rem;
      }
      .sales_return_view  .header-left-side-return{
        /* width: 77.5%; */
        width: 74.5%;
      }
      .sales_return_view .ship-to-Container{
        width: 92%;
      }
      .sales_return_view .sales-report-Logo-Container > div > img{
        width: 150px;
        margin: 0 10px;
        height: 26px;
      }
    
      .sales-report-Logo-Container > div:first-child{
        display: flex;
        align-items: center;
      }
  }
  







  /* for small screens font size small */
  @media screen and (max-width: 800px){
    .sales_report_view .return-footer .sales-report{
      font-size: smaller !important;
    }
  }