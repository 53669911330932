.notfoundContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.notfoundContainer p{
    color: gray;
    font-weight: 200;
}