.profile-container {
    /* max-width: 1400px;
    margin: 0 auto;
    display: table; */
    padding: 10px 10px;
}

.profile-container .profile-hd-main {
    font-size: 25px;
    font-weight: 700 !important;
    padding: 0 0 15px 0;
}

.profile-card-main {
    -webkit-box-shadow: rgba(140, 152, 164, .075) 0 6px 12px 0;
    -moz-box-shadow: rgba(140, 152, 164, .075) 0 6px 12px 0;
    box-shadow: #8c98a413 0 6px 12px;
    border: 1px solid rgba(231, 234, 243, .7);

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Lato, sans-serif;
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
}

.profile-card-content {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profile-card-main .profile-card-hd {
    font-weight: 700 !important;
    font-size: 18px;
    padding-bottom: 10px;
}

.profile-card-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.profile-card-content .profile-card-text .profile-card-text-left {
    font-weight: 700 !important;
    font-size: 18px;
}

.profile-card-content .profile-card-text .profile-card-text-right {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.profile-card-content .profile-card-text .profile-card-text-right .stats-card {
    font-size: 16px;
}

.profile-card-content .profile-card-text .profile-card-text-right .stats-value {
    font-size: 18px;
}

.profile-card-content .profile-card-text .profile-card-text-right .stats-icon {
    font-size: 16px;
    position: relative;
    top: -2px;
}













.profile-card-content .ant-table-thead .ant-table-cell {
    height: 50px;
    background-color: #3c4b58 !important;
    color: #fff;
    font-weight: bold !important;
}

.profile-card-avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.profile-left-card-detail {
    font-size: 15px;
    font-weight: bold !important;
    margin-top: 5px;
}

.profile-card-hours-text {
    font-size: 18px;
    font-weight: bold;
}