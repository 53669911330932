.headingName{
    display: flex;
    justify-content: center;
    color: #3C4B58;
  }
  /* input {
    margin-top: 1rem;
  } */
  
  .upload_checkbox[type="checkbox"]{
    width: 20px;
  }
  .upload_input::file-selector-button {
    font-weight: bold;
    color: #3C4B58;
    padding: 0.3em;
    border: thin solid grey;
    border-radius: 3px;
  }
  .uploader_form{
  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px 20px;
  }
  .steps{
    display: flex;
    flex-direction: column;
  }
  
  .upload_label{
    font-size: 14px;
  }
  .choose_table{
    height: 24px;
    color: #3C4B58;
    font-size: 14px;
  }
  .column_div{
    display: flex;
    flex-wrap: wrap;
  }
  .choose_columns{
    display: flex;
    width: 250px;
  }
  .choose_columns input {
    margin: 0;
    margin-right: 8px;
  }
  .choose_columns > p{
    margin: 8px;
  }
  
  /* Table */
  #tables {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #tables td, #tables th {
    border: 1px solid #ddd;
    padding: 1px 5px;
  }
  
  #tables tr:nth-child(even){background-color: #f2f2f2;}
  
  #tables tr:hover {background-color: #ddd;}
  
  #tables th {
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: left;
    background-color: #5c6e7e;
    color: white;
  }
  /* Table */
  
  .update_radio[type="radio"]{
    border: 0px;
    width: 6%;
    height: 1em;
  }
  .match_columns{
    height: 20px;
    color: #3C4B58;
    /* font-size: 16px; */
    width: 50%;
  }
  
  .submit_div{
    display: flex;
    justify-content: center;
  }
  .submit_btn{
    background-color: rgb(60, 75, 88);
    border-color: rgb(60, 75, 88);
    border-radius: 0% !important;
    color: #fff;
    font-size: 14px !important;
    font-weight: 700;
    height: auto !important;
    /* padding: 6px 35px !important; */
    padding: 10px 35px ;
    transition: all 200ms ease-in-out;
  }