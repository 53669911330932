.queryTool {
    margin-top: 20px;
}

.queryToolInput {
    height: 300px;
    width: 40%;
}
.queryBuuton {
    width: 120px;
    height: 40px !important;
}
.queryTable {
    overflow: auto;
}
.tableBorder {
    border: none !important;
}