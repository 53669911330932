.subtitle{
    background: #e0e6ed;
    /* background: #4c4c4c; */
    /* height: 4vh; */
    padding: 4px 10px;
    position: relative;
}

.subtitleHeading{
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
}
.subtitleHeading h4{
    /* color: #ffffff; */
    margin-bottom: 0px;
}