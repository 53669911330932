.sales_report_view  .sales-report {
    width: 100%;
    margin: 0% auto;
    background-color: white;
    /* margin-top: 5px; */
  }
  
  .sales_report_view  .sales-report-header {
    display: flex;
    width: 109%;
    margin-left: 116px;
    
    /* flex-wrap: wrap; */
    background-color: white;
  }
  
  .sales_report_view  .header-left-side {
    width: 67%;
  }
  
  .sales_report_view  .sales-report-Logo-Container {
    display: flex;
    gap: 10px;
  }
  
  .sales_report_view  .sales-report-Logo_items {
    line-height: 1.4;
    font-weight: 600;
  }
  
  .sales_report_view  .items-detail-container {
    display: flex;
    margin-top: 5px;
    width: 100%;
  }
  
  .sold-to-Container {
    width: 50%;
  }
  
  .sold-to {
    font-weight: 600;
    display: flex;
    background-color: rgb(255, 255, 255);
  }
  .sold-to > div {
    width: 55%;
    border-bottom: 1px solid black;
  }
  
  .sold-to-data {
    width: 100% !important;
    text-align: left;
  }
  
  .ship-to-Container {
    width: 50%;
    margin: 0px 10px;
  }
  
  .invoice-header-right-side {
    width: 30%;
    border: 1px solid black;
    border-style: dashed;
    padding: 5px;
  }
  
  .sales-order-heading {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
  }
  
  .sales-order-items-container:not(.sales_order_content) {
    display: flex;
    margin-top: 1px;
    font-size: 10px;
  }
  .sales_order_content{
    width: 60%;
    font-size: 9px;
  }
  .sales_order_content>div{
    margin-bottom: 1.8px;
  }
  .sales-order-items-left {
    width: 40%;
    font-weight: 600;
  }
  
  .content-block2 > .sales-report-Table-data {
    width: 99%;
    margin: 0% auto;
    /* margin-top: 50px; */
  }
  
  .sales-report-table {
    width: 99%;
    /* border: 1px solid black;
    border-style: dashed; */
    margin-top: 20px;
  }
  
  .invoice-report-table-total{
    margin-left: 78.5%;
    margin-top: 10px;
  width: 20%;
  border: 1px solid black;
  border-style: dashed;
  font-size: 10px;
  }
  .signature{
    margin-left: 78%;
    margin-top: 120px;
    text-align: center;
    width: 154px;
    border-top:1px solid black
  }
  .signature>div:before{
    content : "";
    position: absolute;
    left    : 112px;
    bottom  : 0px;
    height  : 1px;
    width   : 834px;
    border-bottom:1px dashed black;
  }
  .signature hr{
    height: 2px;
    width: 203px;
    margin-left: -35px;
    color: black;
  }
  .invoice-report-table-total td{
    padding: 0;
    padding-left: 5px;
    border: 1px solid black;
    border-style: dashed;
  }
  
  .sales-report-table > tbody > tr > td {
    font-size: 10px;
    font-weight: 500;
  }
  
  .sales-report-table:not(.caption-container) td {
    padding: 0;
    padding-left: 5px;
    border: 1px solid black;
    border-style: dashed;
  }
  
  .sales-report-footer {
    background-color: rgb(61, 67, 146);
    margin-top: 50px;
    width: 100%;
    height: 200px;
  }
  
  .sales_table_col{
    width: 50px;
  }
  .overflow_txt{
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .font_weight{
    font-weight: 700 !important;
  }
  .invoice-w37{
    width: 37% !important;
  }
  @media screen and (max-width: 1350px){
    .sales_report_view .footer_invoice>div{
      width: 80% !important;
      margin-left: -3px;
    } 
    .sales_report_view .sales-report-header{
      margin-left: 10% !important;
    }
    .content-block2 > .sales-report-Table-data{
        width: 85%;
        margin: 0 auto;
    }
    .invoice-report-table-total{
        margin-left: 71.5%;
        margin-top: 10px;
      width: 20%;
      border: 1px solid black;
      border-style: dashed;
      font-size: 10px;
      }
  }
  @media screen and (max-width: 1200px) {
    .sales_report_view .footer_invoice>div{
      width: 81.5% !important;
      margin-left: -22px;
    } 
    .sales-report {
      width: 95%;
    }
    .header-left-side {
      width: 100%;
    }
  
    .sales_report_view .invoice-header-right-side {
      width: 26% !important;
    }
    .sold-to-Container {
      width: 100%;
    }
    .ship-to-Container {
      width: 100%;
    }
    .sales_report_view .sales-report-Logo-Container > div > img{
      width: 150px;
      margin: 0 10px;
      height: 26px;
    }
    .sales_report_view .sales-report-Logo-Container > div:first-child{
      display: flex;
      align-items: center;
    }
  }
  
  @media print {
    .header {page-break-after: avoid;}
    tr{page-break-inside: avoid; 
      page-break-after: auto;}
  }
  
  /*New Css*/
  
  /* .page-header, .page-header-space{
    height: 250px;
    border: none transparent;
  }
  
  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid black;
  }
  
  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
  
  }
  
  .page {
    page-break-after: avoid;
  }
  
  @page {
    margin: 20mm;
  }
  
  @media print {
     thead {display: table-header-group;} 
     tfoot {display: table-footer-group;}
     
     button {display: none;}
     
     body {margin: 0;}
  } */
  
  
  
  
  /* ----------------------------------------------------- */
  
  /* header {
    font-size: 9px;
    color: #f00;
    text-align: center;
    height: 160px;
    width:100%
  }
  
  @page {
    size: A4;
    margin: 11px 17mm 17mm 17mm;
  }
  
  @media print {
    header {
      position: fixed;
      top: 0;
      height: 160px !important;
      overflow: avoid;
    }
  
    .content-block{
      margin-top: 160px;
      page-break-inside: avoid;
      position: relative;
      width: 100%;
      top:1em;  
      left:0px;
      right:0px;
  
    }
  } */
  
  
  
  
  /* @page {
    size: 11in 8.5in;
    margin: 5mm 1mm 5mm 1mm !important;
  } */
  
  .page {
    page-break-after: always;
  }
  
  .footer_invoice {
    height: 188px;
    /* background-color: aquamarine; */
    justify-content: center;
    margin-bottom: 10px;
  
  }
  
  .footer_invoice>div {
    margin-left: 6px;
    position: fixed;
    top: 0;
    /* width: 100vw; */
    /* width: 1080px; */
    width: 1255px;
    /* width: 57%; */
    height: 185px;
    /* margin-bottom: 30px; */
    font-size: 9px;
    background-color: white;
  }
  
  body {
    font-size: 42px;
    font-family: sans-serif;
  }
  
  .spin {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }
  
  .footer2_invoice {
    height: 209px;
    /* background-color: aquamarine; */
    justify-content: center;
    margin-bottom: 10px;
    display: flex;
  }
  
  .footer2_invoice>div {
    margin-left: -14px;
    position: fixed;
    bottom: 0;
    /* width: 1080px; */
    width: 1560px;
    height: 210px;
    background-color: white;
  }
  @media print {
    @page {
    /* size: 11in 8.5in; */
    /* size: auto; */
    /* margin: 5mm 1.4mm 1mm 1.4mm; */
    margin: 5mm 5mm 1mm 5mm;
    /* margin: 5mm 1mm 5mm 1mm; */
  }
    .footer_invoice{
      margin-left: 0px !important;
      margin-bottom: 10px;
      padding-bottom: 10px;
      width: 700px !important;
    }
    .footer_invoice>div{
      margin-top: 0.5px;
      /* margin-left: -40px !important;
      width: 744px !important; */
      /* margin-left: -110px !important; */
      width: 700px !important;
    }
    /* .footer_invoice>div:before {
      content : "";
      position: absolute;
      left    : 112px;
      bottom  : 0px;
      height  : 1px;
      width   : 834px;
    } */
    .invoice-report-table-total{
      /* margin-left: 62.5%; */
      margin-left: 70%;
      margin-top: 10px;
      width: 30% !important;
      border: 1px solid black;
      border-style: dashed;
      font-size: 10px;
    }
    .sales_report_view .total_amount{
      width: 20% !important;
    }
    .sales_report_view  .empty_cell{
      width: 14% !important;
    }
    .sales_report_view  .sum_cell{
      width: 17% !important;
    }
    .sales_report_view  .total_cell{
      width: 49% !important;
    }
    .content-block2 > .sales-report-Table-data{
      width: 99%;
    }
    .sales_report_view .sales-report-header{
      width:115% !important;
    }
    .sales_report_view .sales-report-Logo-Container > div > img{
      width: 150px;
      margin: 0 10px;
      height: 26px;
    }
    .sales_report_view .sales-report-Logo-Container > div:first-child{
      display: flex;
      align-items: center;
    }
    .footer2_invoice{
        margin-left: 0px !important;
        margin-bottom: 10px;
        padding-bottom: 10px;
        width: 700px !important;
        display: block;
      }
      .footer2_invoice>div{
        margin-left: -63px;
        /* margin-left: 10px; */
        position: fixed;
        bottom: 0;
        /* width: 539px; */
        width: 910px;
        /* height: 180px; */
        height: 200px;
        background-color: white;
      }
      .footer_order_p{
        font-size: 10px;
      }
      .invoice-header-right-side {
        width: 26% !important;
      }
  }
  
  /* for small screens font size small */
  @media screen and (max-width: 800px){
    .sales_report_view .footer_invoice .sales-report{
      font-size: smaller !important;
    }
  }