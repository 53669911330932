.totalquantity {
    font-weight: bolder;
    text-align: left;
    width: 100%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.aligntext {
    text-align: center;
}

.odd {
    background: #CFE2FF !important;
    color: black !important;
}