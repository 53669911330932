.mainContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.firstNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.secondNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .site-page-header {
    border: 1px solid rgb(235, 237, 240);
  }
 */
.ant-page-header {
  padding: 0 20px !important;
}

.ant-menu-horizontal {
  background: #3c4b58 !important;
}

.ant-menu-submenu-horizontal .ant-menu-title-content {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  /* height: 40px !important; */
  height: 28px !important;
  line-height: 20px !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  /* height: 40px !important; */
  height: 28px !important;
  line-height: 20px !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item .ant-menu-title-content {
  font-size: 14px;
}

.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-title-content:hover,
.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-title-content {
  color: #3c4b58;
}

.ant-menu-submenu-title:hover {
  background-color: #3c4b58;
}

.ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
  color: white !important;
}

.ant-menu-submenu-arrow:hover {
  color: white !important;
}

.ant-menu-submenu-title:hover .ant-menu-title-content {
  color: white;
}

.ant-menu-title-content:hover {
  color: white !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5c6e7e;
  line-height: 22px !important;
  height: 40px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background-color: #5c6e7e;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #ffffff !important;
}

.ant-menu-title-content a:hover {
  color: white !important;
}

input[readonly] {
  /* color: rgba(0,0,0,.60) !important; */
}

textarea[readonly] {
  /* color: rgba(0,0,0,.60) !important; */
}

.secondSectionRow .ant-row.ant-form-item:not(.name-field-form) .ant-form-item-label {
  display: block;
  flex: 0 0 27%;
  max-width: 27%;
}

.secondSectionRow .ant-row .name-field-form .ant-form-item-label {
  display: block;
  flex: 0 0 13.5%;
  max-width: 13.5%;
}

.crudContainer .ant-tag-green {
  color: white;
  background: #389e0d;
  border-color: #389e0d;
  border-radius: 3px;
  font-size: 11px;
}

/* 
.ant-input {
  border: none !important;
  border-radius: 3px !important;
} */

/*  adding back borders of login form  */
/* .ant-login-form > input { 
  border: 1px solid #c4c4c4!important;
  border-radius: 3px !important;
} */


/* 
.show-border {
    border: 1px solid #d9d9d9 !important;
    border-radius: 3px;
} */
/* 
.ant-select:not(.ant-select-customize-input) .ant-select-selector {

  border: none !important;
}
.ant-table-filter-dropdown .ant-input {
  border: none !important;
} */


.ant-form-item-label-left {
  /* text-align: center !important; */
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
}

.ant-pagination-item-active {
  background: #3c4b58 !important;
  color: white !important;
}

.btn-main {
  border-radius: 4px !important;
}

.h4,
h4 {
  font-size: 14px !important;
}

.h3,
h3 {
  font-size: 1.17em !important;
}

.site-page-header .ant-dropdown-menu-item:hover,
.site-page-header .ant-dropdown-menu-submenu-title:hover {
  background-color: #707070 !important;
  color: #ffffff;
}

.ant-menu-submenu-popup>.ant-menu {
  background-color: #fff;
  border-bottom: 3px solid #3c4b58;
}

.ant-btn-primary,
.ant-btn-danger {
  border-radius: 4px !important;
  font-size: 12px !important;
  height: 28px !important;
  /* padding: 3px 12px !important; */
  padding: 1px 12px !important;
}

.ant-btn-primary.ant-btn-sm {
  height: 24px !important;
  padding: 2px 12px !important;
}

.ant-form-item {
  margin-bottom: 4px !important;
}

.form-margin {
  margin-top: -5px !important;
}

.ml-10 {
  margin-left: 10px;
}

.edit-Button {
  border-radius: 50% !important;
}

/*Sidebar sub menu css*/
.sub_sub_menu a:hover {
  color: #6F3700 !important;
}

.sub_sub_menu .ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #6F3700 !important;
}

.sub_menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
  color: #6F3700 !important;
}

.tab_button {
  background: #3d4b58 !important;
  color: #fff !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}

.tab_button.active {
  background: green !important;
}

.tab_button .ant-btn-loading-icon span[role=img] {
  margin-bottom: 8px;
}

.sub_menuName {
  display: flex;
  align-items: center;
  gap: 7px;
}

.down_icon {
  margin-top: 2px;
}

.menu_container {
  height: 40px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1200px) {
  .secondSectionRow .ant-row.ant-form-item:not(.name-field-form) .ant-form-item-label {
    display: block;
    flex: 1 0 27%;
    max-width: 32%;
  }
}