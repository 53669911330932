.mainProductContainer {
    /* height: 78vh; */
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
}

/* scroll  */
.mainProductContainer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.mainProductContainer::-webkit-scrollbar {
    cursor: pointer !important;

}

*::-webkit-scrollbar-thumb {
    background-color: #b1b1b1;
    border-radius: 10px;
    box-shadow: none;
    border-color: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #858585;
    cursor: grab;
}

/*  */

.formProduct {
    /* padding: 15px 5px 0px 5px; */
    padding: 5px 5px 0px 5px;
}


.formProduct .styleInput {
    border-radius: 3px;
}

.formProduct .styleInput:hover {
    border-color: gray;
    color: gray
}

.formProduct .styleInput:focus {
    box-shadow: none;
    border-color: gray;
}

.ant-input-number {
    border: 1px solid #d9d9d9;
    border-radius: 3px !important;
    width: 100% !important;
}

.ant-input-number-input-wrap {
    border-radius: 3px !important;
}

.ant-select-selector,
.ant-input-number-input {
    border-radius: 3px !important;
}

.ant-select-selector:hover {
    border-color: gray !important;
    color: gray !important
}

.ant-select-selector:focus {
    border-color: gray !important;
    color: gray !important
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: gray !important;
    box-shadow: none !important;
}





.ant-input-borderless,
.ant-input-borderless-disabled,
.ant-input-borderless-focused,
.ant-input-borderless:focus,
.ant-input-borderless:hover,
.ant-input-borderless[disabled] {
    background-color: #ffffff;
}


/* .ant-input.ant-input-borderless, .ant-input.ant-input-borderless-disabled, .ant-input-borderless-focused, .ant-input.ant-input-borderless:focus, .ant-input.ant-input-borderless:hover, .ant-input.ant-input-borderless[disabled] {
    background-color: white !important;
    border: none!important;
    box-shadow: none!important;
} */


/* .ant-form-item-control-input-content .ant-input.ant-input-borderless, .ant-input.ant-input-borderless-disabled, .ant-input-borderless-focused, .ant-input.ant-input-borderless:focus,
.ant-input.ant-input-borderless:hover, .ant-input.ant-input-borderless[disabled] {
  background-color: #ffffff!important;
  border: none!important;
  box-shadow: none!important;
  color: black !important;
} */


/* .ant-picker-input {
    color: black !important;
  } */



/* input:disabled {
    color: black !important;
} */

.ant-input-disabled {
    background-color: white;
    /* color: #242424 !important; */
}

/* .ant-form-item-control-input-content {
    color: black !important;
} */

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {

    background: #ffffff !important;

}

.ant-input-number-disabled {


    background-color: #ffffff !important;
}

.ant-picker-input>input[disabled] {

    /* background-color: #ffffff !important; */
}

.ant-picker.ant-picker-disabled {

    background-color: #ffffff !important;

}



.productSpan {
    color: #777777;
    position: absolute;
    z-index: 1;
    left: 14px;
    top: -12px;
    background: #fff;
    padding: 0 5px;
    font-size: 0.85rem;
    transition: all 0.1s ease-in-out;
    /* border-radius: 10px; */
}

.productSpan:hover {
    cursor: default !important;
    user-select: none;
}

.customRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* padding-right: 10px; */
    /* margin-right:20px; */
    /* padding-left: 5%; */
}

.customRow-Customer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* padding-right: 10px; */
    /* margin-right: 20px; */
    /* padding-left: 5%; */
}

.customCols {
    flex-shrink: 1;
    width: 400px;
    min-width: 300px;
    margin: 0 10px;
}

/* .slc .ant-form-item-explain-error{
    display: none !important;
} */

.ant-form-item-explain-error {
    display: flex;
    justify-content: flex-end;
    font-size: 0.7rem;
}

.productTable {
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* .formProduct .ant-input[disabled]{
    background: #fff !important;
} */

/* .formProduct .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background: #fff !important;
} */

.customRowGenerate {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 5%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* padding-right: 10px; */
}

.customColsGenerate {
    width: 400px;
    flex-shrink: 1;
    min-width: 300px;
    margin: 0 10px;
}

.modalSection {
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 792px) {
    .customRow {
        justify-content: center;
        padding-left: 0;
    }

    .customRowGenerate {
        justify-content: center;
        padding-left: 0;
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 1038px) {
    .customRow {
        justify-content: center;
        padding-left: 0;
    }
}

/*  .generateBTN:hover{
      border-color: #060606 !important;
      color: #060606 !important;
  }
  .generateBTN:focus{
      border-color: gray !important;
      color: #060606 !important;
      box-shadow: none !important;
  }
  .generateBTN::after{
       color:#060606;
       box-shadow: none !important;
  } */


.loaderDiv {
    position: absolute;
    background: rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.undoDiv {
    position: absolute;
    top: 36px;
    right: 10px;

}

.undoDiv .undoBtn {
    color: #242424;
    background: transparent;
}

.undoDiv .undoBtn:hover {
    color: gray;
    background: transparent;
}

.ant-tooltip {
    font-variant: normal !important;
}


.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

.ant-tabs {
    padding: 5px !important;
}


.ant-form-item-label>label {
    color: #525252 !important;
    font-size: 13px !important;
}


.productListDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    background: #e0e6ed;

}

.productListDiv .ant-btn-icon-only {
    background-color: white !important;
    width: 25px;
    height: 25px;
    min-width: initial;
    margin: 2px !important;
}

.h-100 {
    height: 100%;
}

.productTable .ant-table .ant-tooltip {
    display: none !important;
}

.b-radius-5 {
    border-radius: 5px;
}

.b-radius-10 {
    border-radius: 10px;
}

.float-right {
    float: right;
}

.customer-button {
    margin: 5px 0;
    right: 10px;
    background: #ececec;
}

.without_bg .ant-input-borderless {
    background-color: transparent !important;
}

@media only screen and (max-width: 767px) {
    .customCols {
        width: initial !important;
        /* min-width: initial!important; */
    }

    .styleForm {
        height: initial;
    }
}