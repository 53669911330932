$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 700px,
        lg: 992px,
        xl: 1200px,
        xxl: 1680px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1600px
);

// Import BT sources
@import "../node_modules/bootstrap/scss/bootstrap";


// Iftikhar CSS styling

.main_cont {
        display: flex;
        width: 100%;
}

.contentWithSidebar {
        // width: 100%;
        // width: 84%;
        width: 82%;
        z-index: 0;
        margin: 14px auto;
}



.oms-sidebar {
        background-color: #FFF;
        box-shadow: 0px 0 4px #ccc;
        min-width: 282px;
        max-width: 282px;
        // min-width: 238px;
        // max-width: 238px;
        min-height: 100vh;
        padding: 15px;
        transition: all .5s !important;
        z-index: 1;

        .oms-sidebar-content {
                position: sticky;
                top: 2rem;
        }

        .user-info {
                width: 100%;
                display: flex;
                align-items: center;

                .user-circle {
                        width: 50px;
                        height: 50px;
                        background-color: #ebe8e8;
                        border-radius: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                }

                .user-name {
                        font-weight: bold;
                        margin-left: 15px;
                }
        }

        .brand-logo {
                width: 100%;
                margin-top: 15px;
                margin-bottom: 15px;
                border-bottom: solid 1px #CCC;
                padding-bottom: 10px;

                img {
                        width: 100%;
                        max-width: 100%;
                }
        }

        .sidebar_content {
                width: 100%;
                margin-top: 25px;

                .ant-layout-sider {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        min-width: 100% !important;
                        width: 100% !important;

                        .ant-menu-submenu-title,
                        .ant-menu-item {
                                padding-left: 10px !important;
                                font-weight: 500;
                                margin-bottom: 0;

                                &:hover {
                                        background-color: transparent !important;
                                }
                        }

                        .ant-menu-inline {
                                border: none;
                        }

                        .ant-menu-submenu-arrow {
                                color: #000 !important;
                        }

                        .ant-menu-title-content {
                                &:hover {
                                        color: #6F3700 !important;
                                }
                        }

                        .ant-menu-item-only-child a {
                                &:hover {
                                        color: #6F3700 !important;
                                }
                        }

                        .ant-menu-submenu-title:hover .ant-menu-title-content {
                                color: #000 !important;
                        }

                        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                                height: 40px !important;
                                line-height: 40px !important;
                        }

                        .ant-menu-inline .ant-menu-item:after {
                                border: none !important;
                        }

                }
        }

        .oms_sub_nav {
                background-color: #f3f3f3;
        }
}

// New CSS styling
.header_class {
        .logobrand {
                img {
                        min-width: 160px;
                }
        }

        .brand-para {
                // padding-top: 10px;
                font-size: 14px;
                font-weight: 500;
        }

        .right-content-header {
                // padding-top: 10px;
                font-size: 14px;

                .print_icon {
                        width: 30px;
                        height: 30px;
                        background-color: #ebe8e8;
                        border-radius: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        margin-left: 5px;
                        color: rgb(60, 75, 88);
                        transition: 200ms;

                        &:hover {
                                background-color: rgb(60, 75, 88);
                                cursor: pointer;
                                color: #fff;
                        }
                }
        }

        .main_form {
                label {
                        font-weight: 500;
                        // margin-bottom: 5px;
                        margin-bottom: 1px;

                }

                .ant-select {
                        width: 100% !important;
                        // width: 187px !important;
                        margin-bottom: 0px;


                        .ant-select-selector {
                                border-radius: 0px !important;
                                border: 1px solid #000;
                                // border: 1px solid #000 !important;

                        }

                        .ant-select-arrow {
                                color: #000;
                        }
                }

                .ant-select-selection-item {
                        font-size: 14px;
                }

                .ant-picker {
                        border-radius: 0px !important;
                        border: 1px solid #000;

                        // border: 1px solid #000 !important;
                        .ant-picker-suffix {
                                color: #000;
                        }
                }

                button {
                        background-color: rgb(60, 75, 88);
                        border-color: rgb(60, 75, 88);
                        border-radius: 0% !important;
                        color: #fff;
                        font-size: 14px !important;
                        font-weight: 700;
                        height: auto !important;
                        // padding: 6px 35px !important;
                        padding: 1px 25px;
                        transition: all 200ms ease-in-out;
                        // margin-bottom: 5px !important;

                        &:hover {
                                background-color: #000;

                        }
                }

                .full_date {
                        .ant-picker {
                                width: 100% !important;
                                margin-bottom: 0px;

                        }
                }

                .no_visibility {
                        visibility: hidden !important;
                }

                /*-------------OMS OTS Tock Report-------------*/
                .checkboxes {
                        display: flex;
                        flex-wrap: wrap;

                        .checkbox_content {
                                // width: 145px;
                                margin-bottom: 5px;

                                label {
                                        font-size: 12px;
                                        margin-bottom: 0px;

                                }

                                .ant-form-item-control-input {
                                        min-height: 5px
                                }
                        }
                }

                .sku_input {
                        input {
                                width: 100% !important;
                                border: 1px solid #000;
                                margin-bottom: 10px;
                        }
                }

        }

        .ant-table {
                font-size: 12px !important;

                th {
                        font-size: 12px;
                }

                td {
                        font-size: 12px;

                        p {
                                padding: 5px 0px;
                        }
                }
        }

        .ant-pagination {
                .ant-pagination-item-link {
                        color: #000;
                        display: flex;
                        justify-content: center;
                        margin: 0% auto;
                }
        }
}

.header_class2 {
        width: 1200px;
}

/*-------------------------Login CSS-------------------------*/
.container-login {
        .ant-input {
                border-radius: 0px !important;
                border: 1px solid #000;
        }

        .loginButton {
                background-color: rgb(60, 75, 88);
                border-color: rgb(60, 75, 88);
                border-radius: 0% !important;
                color: #fff;
                font-size: 16px !important;
                font-weight: 700;
                height: auto !important;
                padding: 6px 35px !important;
                margin-bottom: 5px !important;

        }

        .login-title-area {
                .login-hd {
                        font-weight: 500;
                }
        }

        .login-form-text {
                font-size: 14px;

                .links {
                        font-weight: 500;
                        color: rgb(60, 75, 88);

                        &:hover {
                                text-decoration: underline;
                        }
                }
        }

        .footerLogin {
                p {
                        font-size: 14px;
                }
        }
}

/*-------------------------Navbar CSS-------------------------*/
.firstNav {
        .ant-page-header-heading {
                .logoImage {
                        width: 160px;
                        margin: 10px 0px;
                }
        }

        .ant-page-header-heading-extra {
                .ant-space {
                        gap: 15px;
                }

                .ant-select-selector {
                        border-radius: 0px !important;

                        // border: 1px solid #000 !important;
                        input {
                                border: none;
                                outline: none;
                                box-shadow: none;

                                &:hover {
                                        border: none;
                                        outline: none;
                                        box-shadow: none;
                                }

                                &:focus {
                                        border: none;
                                        outline: none;
                                        box-shadow: none;
                                }
                        }

                        .ant-input-group-addon {
                                background-color: transparent !important;

                                button {
                                        background-color: transparent;
                                        /* border-color: #3C4B58; */
                                        border-radius: 0% !important;
                                        color: black;
                                        font-size: 16px !important;
                                        font-weight: 700;
                                        height: auto !important;
                                        display: flex;
                                        justify-content: center;
                                        padding: 6px 5px !important;
                                        margin-right: 4px;
                                        align-items: center;
                                        border: 0;
                                        box-shadow: none;

                                        &:hover {
                                                background-color: transparent;
                                                border: 0;
                                                box-shadow: none;
                                                // box-shadow: 0 0 0 0.1rem #3C4B58;
                                        }

                                        &:focus {
                                                background-color: transparent;
                                                border: 0;
                                                box-shadow: none;
                                                // box-shadow: 0 0 0 0.1rem #3C4B58;
                                        }

                                        &:active {
                                                background-color: transparent;
                                                border: 0;
                                                box-shadow: none;
                                                // box-shadow: 0 0 0 0.1rem #3C4B58;
                                        }

                                        &:after {
                                                display: none;
                                        }
                                }
                        }

                }

                .nav_btn {
                        background-color: #ebe8e8;
                        border-color: #ebe8e8;
                        border-radius: 0% !important;
                        color: #3C4B58;
                        font-size: 16px !important;
                        font-weight: 500;
                        height: auto !important;
                        padding: 6px 10px !important;
                        transition: all 200ms ease-in-out;

                        &:hover {
                                background-color: #3C4B58;
                                color: #fff;
                        }
                }

                .user_dropdown {
                        // background-color: #3C4B58;
                        // border-color: #3C4B58;
                        background-color: #fff;
                        border-color: #fff;
                        border-radius: 0% !important;
                        color: #3C4B58;
                        font-size: 16px !important;
                        font-weight: 700;
                        height: auto !important;
                        display: flex;
                        justify-content: center;
                        padding: 2px 8px !important;
                        align-items: center;
                        transition: all 200ms ease-in-out;

                        button {
                                color: #3C4B58;
                                padding: 4px 0px;
                        }

                        i {
                                font-size: 14px;
                        }

                        &:hover {
                                box-shadow: 0 0 0 0.2rem #ffffff;
                        }
                }

                .user_detail_btn:nth-child(2) {
                        width: 60px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                }
        }
}

.business_class {
        width: 1400px !important
}

.business_class.full-width {
        width: 100% !important;
        max-width: 100% !important;
}

.business_header_oms {
        // width: 1600px;

        .business_class_oms {
                // width: 1224px;
                margin: 0% auto;
        }

        td {
                padding: 2px !important;
        }

        .table_distributor-data {
                margin: 0 auto;
                max-height: 600px;
                overflow: auto;
                width: 1800px;
        }
}

.immediate_table {
        thead {
                th {
                        padding-right: 8px;
                }
        }
}

/*-------------------------OMS Stock additional CSS-------------------------*/
.stock_table {
        th {
                div {
                        width: max-content;
                        margin: 0px 5px;
                }
        }
}

.myclass {
        text-align: center;
}

.full_date {

        width: 100% !important;
        margin-bottom: 0px;


}

.full_date_oms {
        width: 187px !important;
}


.sales_target_report_table {


        .ant-table-thead {
                th {
                        border: 1px solid rgba(70, 70, 70, 0.2) !important;
                        border-bottom: none !important;
                }



                .b_left_none {
                        border-left: none !important
                }

                .b_right_none {
                        border-right: none !important
                }

                .b_none {
                        border: none !important;
                }

                .b_bottom {
                        border-bottom: 1px solid rgba(70, 70, 70, 0.2) !important;
                }
        }
}

.sales_target_report_table_dashboard {
        table {
                // width: auto !important;
                width: max-content !important;
        }
}

.ant-table-column-title {
        width: max-content;

}

.maxWidth {
        max-width: 1400px;
        margin: 0 auto;
}

.ant-input-affix-wrapper {
        // border: 1px solid black !important;
        border: 1px solid black;
}

.hello {
        table {
                th {

                        font-size: 10px !important;
                }

                td {
                        font-size: 10px !important;
                }
        }
}

.ant-menu-vertical {

        max-height: calc(100vh - 400px) !important;
}

::-webkit-scrollbar {
        width: 15px;
        height: 15px;

}

::-webkit-scrollbar-track {
        background-color: #fff;
}

::-webkit-scrollbar-thumb {
        background-color: rgb(218, 218, 218);
        border-radius: 10px;
        /* border: 3px solid #ffffff; */
}

.formFullWidth {
        .ant-col {
                max-width: 100% !important;
        }

}


.trapezoid {
        border-bottom: 30px solid #e2e2e2;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        transform: rotate(90deg);
        height: 0;
        width: 45px;
        border-radius: 20px 20px 0px 0px;
        cursor: pointer;

        span {
                transform: rotate(90deg);
                margin: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #212529;
                font-size: large;

        }

        &:hover {
                cursor: pointer;
        }
}

.-m-239 {
        // margin-left: -239px;
        margin-left: -281px;
}


.ant-menu-submenu {
        div {
                height: 36px !important;
                font-weight: normal !important;
        }
}

.ant-menu-item {
        height: 30px !important;
}


.immedidate_ots_baseline {
        table tr th.ant-table-cell {
                vertical-align: bottom;
        }

        .ant-table-row {
                .ant-table-cell:nth-child(n+18):nth-child(-n+34) {
                        border-right: 1px solid #bbbbbb;
                }
        }
}

.expiry-timer {
        position: absolute;
        right: 25px;
        top: 8px;
        color: white;
}

.bg-transparent .ant-input {
        background-color: transparent !important;
}