.product-detail-main-wrapper {
    padding: 15px 0 30px 0;
    overflow-x: hidden;
}

.product-detail-main {
    max-width: 980px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
}

.prod-det-title-hd {
    color: white;
    font-weight: 400;
}

.product-detail-content {
    padding: 30px 15px 10px 15px;
}


.date-filter {
    /* float: left; */
    width: 100%;
    border: solid 1px #d4d2d2;
    min-height: 50px;
    position: relative;
    border-radius: 5px;
    padding: 5px 15px 10px 15px;
    margin-bottom: 25px;
}

.filter-title {
    font-size: 14px !important;
    color: #333;
    background-color: #fff;
    /* font-family: Poppins-Medium; */
    position: relative;
    top: -16px;
    left: 0px;
    padding: 0 10px 0 10px;
    letter-spacing: 0px;
    display: inline-block;
}

.auto-complete-text-btn {
    border-radius: 5px;
    display: inline-block;
    padding: 7px 10px 8px 11px;
    color: #fff;
    background-color: #3c4b58 !important;
    font-size: 13px;
    border: 0;
    margin: 0 0 0 10px;
    line-height: initial;
}


.date-filter .ant-form-item-label {
    text-align: left;
}

.date-filter .ant-row.ant-form-item {
    margin-bottom: 10px !important;
}

.date-filter .filter-fields {
    width: 375px !important;
}

.sales_report_res_table .ant-table {
    /* overflow: auto; */
    overflow-y: auto;
    height: 59vh;
}

.sales_report_res_table_intransit .ant-table {
    /* overflow: auto; */
    overflow-y: none;
    height: auto;
}

.sales_report_res_table .ant-table-container {
    /* width: 1800px; */
    width: 1420px;
}

.sales_report_res_table_intransit .ant-table-container {
    /* width: 1800px; */
    /* width: 1460px; */
    /* width: 1160px; */
}

.sales_report_res_table2 .ant-table-container {
    /* width: 1800px; */
    width: 1200px !important;
    margin: 0 auto;
}

.maxWidth1430 {
    max-width: 1430px !important;
}

.sales_report_res_table th {
    font-size: 9.33px !important;
    font-weight: 700 !important;
}

.sales_report_res_table td {
    font-size: 9.33px !important;
}

.sales_report_res_table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.max_width_sales_target {
    max-width: 950px;
    margin: 0 auto;
}

.sales_table_summary {
    padding: 2px !important;
}

.sales_report_res_table .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 111;
}

.sales_report_res_table_intransit .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 111;
}

.sales_report_res_table_intransit .ant-table-thead th {
    padding: 5px !important;
}

.sales_target_report_table .b_left {
    border-left: 1px solid rgba(158, 158, 158, 0.5) !important;
    /* border-left: 1px solid rgba(0, 0, 0, 0.5) !important; */
}

.sales_target_report_table .b_right {
    border-right: 1px solid rgba(158, 158, 158, 0.5) !important;
}

.sales_report_res_table .ant-table-cell {
    padding: 3px !important;
}

.fullDateWidthOmsStyleReport > .ant-col-18 {
    max-width: 100% !important;
}

@media only screen and (max-width: 1280px) {
    .date-filter .filter-fields {
        width: 350px !important;
    }
}