.customerContainer {
  height: 78vh;
  overflow-y: auto;

}

.ant-form-vertical .ant-form-item-label {
  white-space: nowrap !important;
}

.customerContainer::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.customerContainer::-webkit-scrollbar {
  cursor: pointer !important;

}

*::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 10px;
  box-shadow: none;
  border-color: none;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #858585;
  cursor: grab;
}

.noData {
  display: block;
  margin-left: auto;
  margin-right: auto
}
.mx-10{
  margin: 0 8px;
}