.container-login {
    background-color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.logoImage-login {
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 60px;
    max-width: 150px;
    filter: invert(1);
}

.login-form-main .loginLogo {
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 60px;
    max-width: 150px;
    user-select: none;
}

.form {
    background-color: #fff;
    width: 70%;
    box-shadow: 0 0 17px -2px rgb(0 0 0 / 15%);
    border-radius: 5px;
    padding: 35px 30px;
    margin: 35px 100px;
    text-align: center;
}

.formPassword {
    background-color: #fff;
    width: 86%;
    box-shadow: 0 0 17px -2px rgb(0 0 0 / 15%);
    border-radius: 5px;
    padding: 86px 30px;
    margin: 35px 100px;
    text-align: center;
}

.login-title-area .loginLogo {
    margin-bottom: 2rem;
}

.login-title-area .login-hd {
    margin-bottom: 2.5rem;
    font-size: 1.5rem !important;
    font-weight: 400;
}

.form .form-inputs .ant-input {
    color: black;
    background-color: #ffffff;
    margin-bottom: 20px;
    border-radius: 5px;
    border-color: #c4c4c4;
    padding: 5px 15px 5px 15px;
}

.form-inputs .ant-input:hover {
    border-color: gray;
}

.form-inputs .ant-input:focus {
    box-shadow: none;
    border-color: gray;
    background-color: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.form-inputs .ant-input::placeholder {
    color: rgb(117, 117, 117);

}

.ant-select-selection-placeholder {
    color: black;
}

/* .__loginButton {

    font-weight: 600;
    margin-Top: 10px;
    border-radius: 4px;
    height: 80px !important;
    text-transform: uppercase;
} */

.form .forgotPassword {
    color: gray;
    font-weight: 500;
    margin: 20px 0 10px 0;
}

.login-form-text {
    font-size: 12px;
    text-align: center;
    color: gray;
    margin: 0px;
}

.login-form-text p {
    margin-bottom: 0px;
}

.links:hover {
    color: black;
}

.ant-form .ant-input>input::placeholder {
    color: red;
}

.headerLogin {
    background: #3c4b58;
    /* height: 7%; */
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 15px;

}

.footerLogin {
    background: #5c6e7e;
    /* height: 7%; */
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 15px;

}

.footerLogin {
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerLogin p {
    margin: 0px;
    font-size: 13px;
    font-weight: 400;
    ;
}

.ant-notification-notice-close-x {
    width: 56px !important;
    height: 46px !important;
    color: rgb(211, 47, 47) !important;
    line-height: 46px !important;
}

@media only screen and (min-width: 1400px) {
    .login-form-text {
        padding: 0px 35px;
    }
}

@media only screen and (max-width: 991px) {
    .copyright-txt {
        padding-bottom: 10px;
    }
}










/* New Code */



/* Add this to your CSS file */
body,
html,
#root {
    height: 100%;
    margin: 0;
}

.max-w-300 {
    max-width: 300px;
}

.logo-login {
    /* height: 40px; */
    width: 170px;
    display: block;
    align-items: center;
    justify-content: center;
    margin: 0px auto 20px;

    text-align: center;
}

.logo-login img {
    max-height: 100%;
    max-width: 100%;
    transition: all 0.2s;
}

.login-form-main {
    width: 300px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-bottom: 22px;
}


.login-form-main h2 {
    font-size: 28px;
    font-weight: 400;
    margin: 0px 0 20px 0;
}

.login-form-main h2.small-hd1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.login-form-main h2.small-hd {
    font-size: 20px;
    margin-bottom: 10px;
}

.login-form-main .ant-form-item-label {
    padding: 0 0 4px !important;
}

.login-form-main .ant-form-item-label label {
    font-weight: 700;
}



.reset-para {
    font-size: 13px !important;
    line-height: 1.6 !important;
}

.by-submit-para {
    font-size: 12px !important;
    line-height: 1.6 !important;
}

.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}



.a-divider-break {
    text-align: center;
    position: relative;
    top: 2px;
    padding-top: 1px;
    margin-bottom: 20px;
    line-height: 0
}

.a-divider-break:after {
    content: "";
    width: 100%;
    background-color: transparent;
    display: block;
    height: 1px;
    border-top: 1px solid #e7e7e7;
    position: absolute;
    top: 52%;
    margin-top: -1px;
    z-index: 1
}

.a-divider-break h5 {
    line-height: 1;
    font-size: 12px;
    color: #767676;
    font-weight: 400;
    z-index: 2;
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 0 8px 0 7px;
    margin: 0;
}

.login-state-3 .input-otp__field {
    height: 44px;
    font-weight: 400;
    border-color: #b1b1b1;
}

.login-state-3 :where(.css-dev-only-do-not-override-3rel02).ant-input-outlined {
    border-color: #b1b1b1;
}






.ant-input.login-input {
    height: 45px;
    margin-bottom: 25px;
}





.login-form-wrapper {
    max-width: 450px;
    /* padding: 20px; */
    /* border: 1px solid #d9d9d9; */
    border-radius: 8px;
}

.login-form-wrapper .login-hd {
    font-size: 28px;
    color: black;
    font-weight: 400;
    margin: 0px 0 20px 0;
    text-align: center;
}

.login-form-wrapper .login-btn-main.ant-btn {
    height: 45px !important;
    margin-bottom: 10px;
    font-size: 16px !important;
}

.login-left-hd {
    font-size: 28px;
    color: white;
    font-weight: 400;
    margin: 0px 0 10px 0;
    text-align: center;
}

.login-left-para {
    font-size: 16px;
    color: white;
    font-weight: 400 !important;
    margin: 0px 0 0px 0;
    text-align: center;
}

.login-left-bottom {
    position: absolute;
    bottom: 20px;
    color: white;
    font-size: 16px;
}