.inputs_Container {
  width: 35%;
  margin: 0% auto;
  margin-top: 10px;
}

.item_Container {
  display: flex;
  flex-wrap: wrap;
}

.logo_Container {
  width: 230px;
}

.logo_Container>img {
  width: 220px;
}

.items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item_left {
  line-height: 1.2;
}

.item_right {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

.item_Images {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.item_Images>img {
  width: 20px;
  cursor: pointer;
}

.login_user {
  display: flex;
  justify-content: flex-end;
}

.userName {
  font-weight: 600;
  padding-left: 5px;
}

.data_Table:first-child {
  margin-top: 0px;

}

.data_Table {
  width: 100%;
  margin-top: 25px;

}

.business_header {
  width: 900px;
}

.table {
  width: 1400px !important;
  margin: 0% auto
}

.table>tbody>tr>td {
  font-size: 10px;
  font-weight: 500;
}

.table:not(.caption-container) td {
  padding: 0;
  padding-left: 5px;
  border: 1px solid rgb(187, 187, 187);
}

.months {
  background-color: rgba(235, 235, 235, 0.884);
}

.months>td {
  font-size: 12px !important;
}

.months>td {
  font-size: 14px;
  font-weight: 600;
}

.serial_No {
  width: 20px;
}

.spring {
  background-color: #e1ecec !important;
}

.fall {
  background-color: #e1ecec !important;
}

.total {
  background-color: #e8f2fc !important;
}

.select_Inputs {
  width: 15em !important;
}

.table_Heading {
  width: 1400px;
  border-left: 1px solid rgb(187, 187, 187);
  border-right: 1px solid rgb(187, 187, 187);
  border-top: 1px solid rgb(187, 187, 187);
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  background-color: gainsboro;
  margin: 0% auto;
}

.fontBold{
  font-weight: 600;
}

.generateButton {
  display: flex;
  justify-content: flex-end;
}

.generateButton>button {
  font-size: 12px;
  border: 1px solid black;
}

.loader {
  display: flex;
  justify-content: center;
}

.table_content {
  margin-top: 5px !important;
  max-width: 1830px;
  margin: 0% auto;
}

.table_content::-webkit-scrollbar {
  width: 3px;
  height: 3px;

}

.table_content::-webkit-scrollbar-track {
  background-color: #fff;
}

.table_content::-webkit-scrollbar-thumb {
  background-color: rgb(170, 170, 170);
  border-radius: 10px;
}

.serial_No {
  width: 26px !important;
}

.second_col {
  width: 50px !important;
}

.business_header {
  width: 650px;
  margin: 0 auto;
}

.marginAuto{
  margin: 0 auto !important;
}
.header_class{
  margin: 0 auto;
}

.color_red{
  color: red;
}
.color_black{
  color: black !important;
}

.color_green{
  color: green;
}

.stripedTable >tr:nth-child(odd) {
  background-color: rgba(235, 235, 235, 0.884);
}
/* 
.abc tr:last-child{
  color: green;
} */

@media print{
  .business_class{
    width: 900px !important;
  }
  .header_class{
    margin: 0px 0px !important;
    width: 900px !important;
  }
  .data_Table .table{
    width: 1400px !important;
  }
  .business_header{
    width: 1400px !important;
  }
  .marginAuto{
    width: 1360px !important;
  }
}

.table_content.full-table-width {
  overflow-x: auto;
  width: 100% !important;
  max-width: 100% !important;
}

.fit-content-width {
  min-width: 100%!important;
  width: fit-content!important;
}

.table_content.full-table-width.scrollbar-style .max-1400-width {
  max-width: 1400px !important;
  margin: 0 auto;
}

.table_Heading-new {
    border-left: 1px solid rgb(187, 187, 187);
  border-right: 1px solid rgb(187, 187, 187);
  border-top: 1px solid rgb(187, 187, 187);
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  background-color: gainsboro;
  margin: 0% auto;
  border: 1px solid rgb(187, 187, 187);
}
table.full-width.business-table-main.width-initial {
  width: initial!important;
}

/* .business-dashboard-table-fit .business-table-main tr td {
  white-space: nowrap;
} */

@media screen and (max-width: 1400px) {
  .inputs_Container {
    width: 50%;
    padding: 10px;
  }

  .table_content {
    /* max-height: 600px; */
    width: 900px;
    margin: 0 auto !important;
    /* overflow: auto; */
  }
  .table_content.full-table-width {
    width: 100%!important;
  }

  .business_class {
    max-width: 1000px !important;
    width: 1000px !important;
  }
}
@media screen and (max-width: 1281px){
  .business_class {
    max-width: 960px !important;
  }
}
@media screen and (max-width: 1000px) {
  .inputs_Container {
    width: 100%;
    padding: 10px;
  }

  .logo_Container {
    width: 100%;
  }

  .items {
    width: 100%;
    margin-top: 10px;
  }
  .business_header{
    width: 1400px !important;
  }
}