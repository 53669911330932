.pageHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 10px;
    background: #5c6e7e;
    /* border-radius: 10px 10px 0 0; */
}

.pageHead h3{
    margin-bottom: 0px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px!important;
}

 .iconBtn{
    border: none !important;
    box-shadow: none !important;
}

.pageHead .iconBtn:hover{
    color:#8b8b8b
}

.pageHead .ant-btn[disabled]{
    border: none;
    background: #fff !important;
    box-shadow: none !important;

}
.pageHead .ant-btn-icon-only {
    width: 20px;
    height: 20px;
    min-width: 20px;
    padding: 1.4px 0;
    font-size: 11px;
}

 .ant-tooltip-inner{
    color: black !important;
    /* background: #8495a5 !important; */
}

.ant-divider-horizontal{
    margin: 0 !important;
}

