.skeleton {
   /* background: #fff; */
   width: 100%;
   height: 20vh;
}

.btnSkeleton {
   display: flex;
   justify-content: flex-end;
   margin-bottom: 5px;
   /* width: 100%;
    background: #f1f1f1; */

}

.headerSkeleton {
   background: #f1f1f1;
   width: 100%;
   height: 7%;
   margin-bottom: 2px;
   display: flex;
   justify-content: space-around;
   align-items: center;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
}

.bodySkeleton {
   background: #f1f1f1;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 10px;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}


.colSkeleton {
   width: 173px;
   border-radius: 10px;
}

/* background: "#f1f1f1", width: "100%", height: "85%", display: "flex", padding: "10px", flexDirection: "column", justifyContent: "space-between", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" */


.productListDiv .subtitle .subtitleHeading {
   padding: 0 !important;

}

.w-10-percent {
   width: 10%;
}

.w-100-percent {
   width: 100%;
}

.b-radius-10 {
   border-radius: 10px;
}

.widthmax {
   width: 96vw !important;
   max-width: 100% !important;
}