.inputs_Container {
  width: 35%;
  margin: 0% auto;
  margin-top: 10px;
}

.item_Container {
  display: flex;
  flex-wrap: wrap;
}

.logo_Container {
  width: 230px;
}

.logo_Container>img {
  width: 220px;
}

.items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item_left {
  line-height: 1.2;
}

.item_right {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

.item_Images {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.item_Images>img {
  width: 20px;
  cursor: pointer;
}

.login_user {
  display: flex;
  justify-content: flex-end;
}

.userName {
  font-weight: 600;
  padding-left: 5px;
}

.data_Table:first-child {
  margin-top: 0px;

}

.data_Table {
  /* width: 850px; */
  width: 100%;
  /* margin: 0% auto; */
  margin-top: 25px;

}

.business_header {
  width: 900px !important;
  margin: 0% auto !important;
}

.table {
  /* table-layout: fixed; */
  /* width: 1360px !important; */
  /* width: 1360px !important; */
  width: 880px !important;
  margin: 0% auto
}

.table>tbody>tr>td {
  font-size: 10px;
  font-weight: 500;
  /* width: 80px; */
}

.table:not(.caption-container) td {
  padding: 0;
  padding-left: 5px;
  border: 1px solid rgb(187, 187, 187);
}

.months {
  background-color: rgba(235, 235, 235, 0.884);
}

.months>td {
  font-size: 12px !important;
}

.months>td {
  font-size: 14px;
  font-weight: 600;
}

.serial_No {
  width: 20px;
}

.spring {
  background-color: #e1ecec !important;
}

.fall {
  background-color: #e1ecec !important;
}

.total {
  background-color: #e8f2fc !important;
}

.select_Inputs {
  width: 15em !important;
}

.table_Heading {
  width: 1360px;
  border-left: 1px solid rgb(187, 187, 187);
  border-right: 1px solid rgb(187, 187, 187);
  border-top: 1px solid rgb(187, 187, 187);
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  background-color: gainsboro;
  margin: 0% auto;
  /* border: 1px solid rgb(187, 187, 187); */
}

.generateButton {
  display: flex;
  justify-content: flex-end;
}

.generateButton>button {
  font-size: 12px;
  border: 1px solid black;
}

.loader {
  display: flex;
  justify-content: center;
}

.table_content {
  /* max-height: 620px; */
  margin-top: 5px !important;
  max-width: 1950px;
  margin: 0% auto;
  /* overflow: auto; */
}

/* .table_content:hover{
    overflow: auto !important;
  } */
.table_content::-webkit-scrollbar {
  width: 3px;
  height: 3px;

}

.table_content::-webkit-scrollbar-track {
  background-color: #fff;
}

.table_content::-webkit-scrollbar-thumb {
  background-color: rgb(170, 170, 170);
  border-radius: 10px;
  /* border: 3px solid #ffffff; */
}

.serial_No {
  width: 26px !important;
}

.second_col {
  width: 50px !important;
}

.business_header {
  width: 650px;
  margin: 0 auto;
}

@media print{
  .table{
    max-width: 880px !important;
  }
  .marginAuto{
    width: 1360px !important;
  }
  .ledger_print{
    margin-left: 0px !important;
    max-width: 1900px !important;
  }

  .ledger_print .ant-table-thead>tr>th, .ant-table-tbody>tr>td, .ant-table tfoot>tr>th, .ant-table tfoot>tr>td{
    font-size: 8px !important;
  }
  .ledger_print .ant-table-thead>tr>th .ant-table tfoot>tr>th{
    font-weight: 800 !important;
    font-size: 9px !important;

  }
}

/* E8F2FC*/
@media screen and (max-width: 1400px) {
  .inputs_Container {
    width: 50%;
    padding: 10px;
  }

  /* .data_Table {
      width: 95%;
    } */
  .table_content {
    /* max-height: 600px; */
    /* width: 1200px; */
    margin: 0 auto !important;
    /* overflow: auto; */
  }
  .international_order{
    width: 940px;
  }
  .customer_ledger_table_content{
    width: 1560px !important;
    margin: 0 auto !important;
  }
  .customer_ledger_table_content > div{
    width: 100% !important;
  }

  .business_class {
    max-width: 1200px !important;
  }
}

@media screen and (max-width: 1000px) {
  .inputs_Container {
    width: 100%;
    padding: 10px;
  }

  .logo_Container {
    width: 100%;
  }

  .items {
    width: 100%;
    margin-top: 10px;
  }
}

.noRecordTable {
  text-align: center;
}