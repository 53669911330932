 .testsss {
     text-align: right !important;
 }

 body {
     background-color: White;
     margin: 0px 0px 0px 0px;
     padding: 0;
     border: none;
     text-align: left;
     font-family: Arial, Verdana, Calibri, Tahoma;
     font-size: 12px;
     color: #000000;
 }

 /* .content-main-wrapper {
     padding: 0;
 } */

 .text-right {
     text-align: right;
 }

 .text-left {
     text-align: left;
 }

 .pl-0 {
     padding-left: 0 !important;
 }

 .pr-0 {
     padding-right: 0 !important;
 }

 .ml-0 {
     margin-left: 0 !important;
 }

 .mr-0 {
     margin-right: 0 !important;
 }

 .report-wrapper-main {
     width: 900px;
     margin: 0 auto !important;

     /*new*/
     /* width: 100%; */
 }

 .report_header {
     width: 650px;
     margin: 0 auto !important;
 }

 .box-space {
     padding-top: 10px;
     padding-bottom: 10px;
 }

 .logobrand {
     /* min-height: 50px; */
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .logobrand img {
     max-width: 112px;
 }

 .brand-para {
     font-size: 12px;
     line-height: 15px;
     margin-bottom: 0;
 }


 .tablestyleControls {
     font-family: arial;
     font-size: 10pt;
     width: 100%;
     color: #3D3D3D;
     border: 1px solid #CDCDCD;
 }

 .tablestyleControls td {
     border: 1px solid #CDCDCD;
     width: 0;
 }

 .tablestyleControls td .ant-row.ant-form-item {
     margin-bottom: 0px !important;
 }

 .submit-btn-btm {
     display: flex !important;
     margin: 5px 0 0 auto !important;
     height: 26px !important;
 }

 .test {
     text-align: right !important;
     background-color: rebeccapurple;
 }

 @media all {
     .page-break {
         display: none;
     }
 }

 /* @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  } */


 @media only screen and (min-width: 767px) {
     .input-width {
         width: 200px !important;
     }
 }

 @media (min-width: 576px) {
     .text-sm-right {
         text-align: right !important;
     }

     .pl-sm-0 {
         padding-left: 0 !important;
     }
 }


 @media only screen and (max-width: 767px) {
     .input-width {
         width: 100%;
     }

     .report-wrapper-main {
         width: 100%;
     }

     .tablestyleControls td {
         width: 100% !important;
         display: block;
         border: 0;
     }

     .tablestyleControls td.no-border-btm {
         border-bottom: 0px;
     }

     .tablestyleControls td+td {
         border-bottom: 1px solid #c1c1c1;
     }

     .submit-btn-btm {
         display: flex !important;
         margin: 5px 0 0 0 !important;
     }
 }

 .width30 {
     width: 30%;
 }

 .width40 {
     width: 40%;
 }

 .width44 {
     width: 44%;
 }

 @media only screen and (max-width: 1200px) {
     .report-wrapper-main {
         width: 100%;
     }
 }

 @media print {

     /* @page {
          size: A4;
          margin: 11mm 17mm 17mm 17mm;
        } */
     footer {
         position: fixed;
         bottom: 0;
     }

     html,
     body {
         width: 210mm;
         height: 297mm;
     }

     .printing_class {
         display: none !important;
     }
 }

 /* report-wrapper-main header_class */


 .report-box-main {
     width: 1150px;
     margin: 0 auto 30px auto !important;
     overflow-x: auto;
 }

 .report-box-main-balance {
     width: 950px;
     margin: 0 auto 30px auto !important;
     overflow-x: auto;
 }

 .report-table-main {
     margin: 0 auto;

     --bs-table-bg: transparent;
     --bs-table-accent-bg: transparent;
     --bs-table-striped-color: #212529;
     --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
     --bs-table-active-color: #212529;
     --bs-table-active-bg: rgba(0, 0, 0, 0.1);
     --bs-table-hover-color: #212529;
     --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
     width: 100%;
     margin-bottom: 1rem;
     color: #212529;
     vertical-align: top;
     border-color: #dee2e6;
 }

 .report-table-main .report-row-head {
     background-color: #3c4a57;
     color: white;
 }

 .report-table-main .report-column-head {
     padding: 5px 15px;
 }

 .report-column-head.first-head {
     width: 300px;
     max-width: 300px;
     min-width: 300px;
 }

 .report-column-head.second-head {
     width: 150px;
     max-width: 150px;
     min-width: 150px;
 }

 .report-hd {
     font-weight: 700 !important;
     font-size: 14px;
 }
 .report-hd1 {
     font-weight: 900 !important;
     font-size: 14px;
 }

 .report-value {
     text-align: right;
     padding: 0 0 0 15px;
 }

 .total-amount {
     font-weight: 700 !important;
     font-size: 14px;
     text-align: right;
     padding: 0 15px;
 }

 .total-amount-left {
     font-weight: 700 !important;
     font-size: 14px;
 }

 .bottom-row {
     border-top: 1px solid #c1c1c1;
 }




 @media print {
     #control-hooks {
         display: none;
     }

     body {
         -webkit-print-color-adjust: exact !important;
         print-color-adjust: exact !important;
     }

     @page {
         /* size: 11in 8.5in; */
         /* size: auto; */
         margin: 5mm 1.4mm 1mm 1.4mm;
         /* margin: 5mm 1mm 5mm 1mm; */
     }

     .report-box-main {
         width: 950px;
         margin: 0 auto 30px auto !important;
         overflow-x: auto;
     }

     .report-table-main {
         margin: 0 auto;

         --bs-table-bg: transparent;
         --bs-table-accent-bg: transparent;
         --bs-table-striped-color: #212529;
         --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
         --bs-table-active-color: #212529;
         --bs-table-active-bg: rgba(0, 0, 0, 0.1);
         --bs-table-hover-color: #212529;
         --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
         width: 100%;
         margin-bottom: 1rem;
         color: #212529;
         vertical-align: top;
         border-color: #dee2e6;
     }

     .report-table-main .report-row-head {
         background-color: #3c4a57;
         color: white;
     }

     .report-table-main .report-column-head {
         padding: 5px 15px;
     }

     .report-column-head.first-head {
         width: 200px;
         max-width: 200px;
         min-width: 200px;
     }

     .report-column-head.second-head {
         width: 50px;
         max-width: 50px;
         min-width: 50px;
     }

     .report-hd {
         font-weight: 700 !important;
         font-size: 14px;
     }

     .report-value {
         text-align: right;
         padding: 0 15px;
     }

     .total-amount {
         font-weight: 700 !important;
         font-size: 14px;
         text-align: right;
         padding: 0 15px;
     }

     .total-amount-left {
         font-weight: 700 !important;
         font-size: 14px;
     }

     .bottom-row {
         border-top: 1px solid #c1c1c1;
     }

 }