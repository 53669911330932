.ant-table-body {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ant-table-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


.ant-table-body::-webkit-scrollbar {
  cursor: pointer !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 10px;
  box-shadow: none;
  border-color: none;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #858585;
  cursor: grab;
}

.ant-table-cell-row-hover {
  background-color: rgb(146, 164, 181) !important;
}

.ant-table-cell-row-hover {
  color: #ffffff !important;
  /* font-weight: bolder; */
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #fbfbfb;
}


.ant-table-thead .ant-table-cell {
  background: #e0e6ed !important;
  padding: 5px 8px ;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  text-align: left;
  word-break: break-word;
  line-height: 13px !important;
  font-size: 12px;
}

.specialtyByStyleLineHeight .ant-table-cell {
  line-height: 4px !important;
}

.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small tfoot>tr>td,
.ant-table.ant-table-small tfoot>tr>th {
  padding: 2px 8px ;
}

.ant-table.ant-table-small .ant-table-tbody>tr.ant-table-measure-row:first-child>td {
  padding: 0px !important;
}