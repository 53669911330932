.sales-report {
  width: 100%;
  margin: 0% auto;
  background-color: white;
  /* margin-top: 5px; */
}

.credit-report-header {
  display: flex;
  width: 109%;
  margin-left: 94px;
  
  /* flex-wrap: wrap; */
  background-color: white;
}

.credit-report-header .header-left-side {
  width: 44%;
}

.credit-report-header .sales-report-Logo-Container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sales-report-Logo_items {
  line-height: 1.4;
  font-weight: 600;
}

.items-detail-container {
  display: flex;
  margin-top: 5px;
  width: 100%;
}

.credit-report-header .sold-to-Container {
  width: 35%;
}

.sold-to {
  font-weight: 600;
  display: flex;
  background-color: rgb(255, 255, 255);
}
.sold-to > div {
  width: 55%;
  border-bottom: 1px solid black;
}

.sold-to-data {
  width: 100% !important;
  text-align: left;
}

.ship-to-Container {
  width: 50%;
  margin: 0px 10px;
}

.credit-report-header .header-right-side2 {
  width: 20%;
  /* border: 1px solid black;
  border-style: dashed; */
  padding: 5px;
}

.credit-report-header .header-right-side3 {
  /* border: 1px solid black; */
  /* border-style: dashed; */
  padding: 5px;
}

.credit-report-header .sales-order-heading {
  text-align: left;
  font-weight: 700;
  font-size: 14px;
}

.sales-order-items-container:not(.sales_order_content) {
  display: flex;
  margin-top: 5px;
  font-size: 10px;
}

.sales-order-items-container2{
  background-color: rgb(184, 184, 184);
  padding: 5px;
  display: flex;
  align-items: center;
}
.credit-report-header .sales_order_content2{
  /* width: 100%; */
}

.credit-report-header .sales_order_content2>div{
  font-size: 24px;
  text-align: right;
}

.credit-report-header .sales_order_content>div{
  margin-bottom: 1.8px;
  text-align: right;
  font-size: 14px;
}
.sales-order-items-left {
  width: 40%;
  font-weight: 600;
}

.sales-report-Table-data {
  width: 85%;
  margin: 0% auto;
  /* margin-top: 50px; */
}

.sales-report-table {
  width: 100%;
  /* border: 1px solid black;
  border-style: dashed; */
  margin-top: 20px;
}

.sales-report-table-total2{
  margin-left: 72.5%;
  margin-top: 10px;
  width: 20%;
  height: 30px;
/* border: 1px solid black; */
/* border-style: dashed; */
  font-size: 10px;
}
.signature{
  margin-left: 78%;
  margin-top: 120px;
  text-align: center;
  width: 154px;
  border-top:1px solid black
}
.signature>div:before{
  content : "";
  position: absolute;
  left    : 112px;
  bottom  : 0px;
  height  : 1px;
  width   : 834px;
  border-bottom:1px dashed black;
}
.signature hr{
  height: 2px;
  width: 203px;
  margin-left: -35px;
  color: black;
}
.sales-report-table-total2 td{
  padding: 0;
  padding-left: 5px;
  border: 1px solid black;
  border-style: dashed;
}

.sales-report-table > tbody > tr > td {
  font-size: 10px;
  font-weight: 500;
}

.sales-report-table:not(.caption-container) td {
  padding: 0;
  padding-left: 5px;
  border: 1px solid black;
  border-style: dashed;
}

.sales-report-footer {
  background-color: rgb(61, 67, 146);
  margin-top: 50px;
  width: 100%;
  height: 200px;
}

.sales_table_col{
  width: 50px;
}
.overflow_txt{
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.font_weight{
  font-weight: 700 !important;
}

.credit-report-header .sales-report-Logo-Container > div > img{
  width: 150px;
  margin: 0 10px;
  height: 26px;
}

@media screen and (max-width: 1282px){
  .credit-report-header .header-left-side {
    width: 52.5%;
  }

  .credit-report-header .header-right-side2{
  width: 25%;

  }
}


@media screen and (max-width: 1200px) {
  .sales-report {
    width: 90%;
  }
  .header-left-side {
    width: 100%;
  }

  .header-right-side2 {
    width: 100%;
  }
  .sold-to-Container {
    width: 100%;
  }
  .ship-to-Container {
    width: 100%;
  }
}

@media print {
  .header {page-break-after: avoid;}
  tr{page-break-inside: avoid; 
    page-break-after: auto;}
}

/*New Css*/

/* .page-header, .page-header-space{
  height: 250px;
  border: none transparent;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid black;
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;

}

.page {
  page-break-after: avoid;
}

@page {
  margin: 20mm;
}

@media print {
   thead {display: table-header-group;} 
   tfoot {display: table-footer-group;}
   
   button {display: none;}
   
   body {margin: 0;}
} */




/* ----------------------------------------------------- */

/* header {
  font-size: 9px;
  color: #f00;
  text-align: center;
  height: 160px;
  width:100%
}

@page {
  size: A4;
  margin: 11px 17mm 17mm 17mm;
}

@media print {
  header {
    position: fixed;
    top: 0;
    height: 160px !important;
    overflow: avoid;
  }

  .content-block{
    margin-top: 160px;
    page-break-inside: avoid;
    position: relative;
    width: 100%;
    top:1em;  
    left:0px;
    right:0px;

  }
} */




/* @page {
  size: 11in 8.5in;
} */

.page {
  page-break-after: always;
}

.footer {
  height: 185px;
  /* background-color: aquamarine; */
  justify-content: center;
  margin-bottom: 10px;

}

.footer>div {
  margin-left: -14px;
  position: fixed;
  top: 0;
  /* width: 100vw; */
  width: 1080px;
  /* width: 57%; */
  height: 185px;
  /* margin-bottom: 30px; */
  font-size: 9px;
  background-color: white;
}

body {
  font-size: 42px;
  font-family: sans-serif;
}

.spin {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}

.barcode>svg{
  display: flex;
  justify-content: flex-start;
  width: 100px !important;
}

@media print {
  .footer{
    margin-left: 0px !important;
    margin-bottom: 10px;
    padding-bottom: 10px;
    width: 700px !important;
  }
  .footer>div{
    margin-top: 0.5px;
    margin-left: -40px !important;
    width: 744px !important;
  }
  .footer>div:before {
    content : "";
    position: absolute;
    left    : 112px;
    bottom  : 0px;
    height  : 1px;
    width   : 834px;
    /* border-bottom:1px dashed black; */
  }
  .sales-report-table-total2{
    margin-left: 65.5%;
    margin-top: 10px;
    width: 30% !important;
    /* border: 1px solid black; */
    /* border-style: dashed; */
    font-size: 10px;
  }
  .total_amount{
    width: 20% !important;
  }
  .empty_cell{
    width: 14% !important;
  }
  .sum_cell_credit{
    width: 36% !important;
  }
  .total_cell{
    width: 49% !important;
  }
  .sales-report-Table-data{
    width: 100%;
  }
  .credit-report-header{
    /* margin-left: 20px; */
    width:115% !important;
  }
  .credit-report-header .header-left-side {
    width: 39%;
  }
  .sales-order-items-container2{
    background-color: rgb(184, 184, 184);
    padding: 5px;
  }
  .header-right-side2{
    width: 30% !important;
  }
  .header-right-side{
    width: 27.5% !important;
  }
}